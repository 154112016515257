import React from "react";
import Card from "react-bootstrap/Card";
import { ImHeart  } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Ranjan Rajesh </span>
            from <span className="purple"> Bangalore, India.</span>
            <br />
            I am Student.
            <br />
 
          </p>
          <ul>
            <li className="about-activity">
              <ImHeart  /> Playing Badminton
            </li>
            <li className="about-activity">
              <ImHeart  /> cycling
            </li>
            <li className="about-activity">
              <ImHeart  /> Swiming
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
           {" "}
          </p>
          <footer className="blockquote-footer">Ranjan Rajesh</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
